import React from 'react';

import { FieldArray } from 'redux-form';

import { ENUM_OPTIONS_FIELD } from '@og-pro/shared-config/customFormService/customField';

import { OptionsField } from './OptionsField';

export const MultipleChoiceOptions = () => {
    return <FieldArray component={OptionsField} name={ENUM_OPTIONS_FIELD} />;
};
