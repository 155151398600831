import { useCustomFormServiceMutation } from './useCustomFormService';
import { editCustomField } from './queries';

export const useUpdateCustomField = (id) => {
    return useCustomFormServiceMutation({
        mutationKey: 'updateCustomField',
        mutation: editCustomField,
        enabled: true,
        params: { id },
    });
};
