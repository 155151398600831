import { showSnackbar } from './notification';
import { resourceManager } from '../helpers';

export const LOAD_LIST = 'requestTypes/LOAD_LIST';
export const LOAD_LIST_FAIL = 'requestTypes/LOAD_LIST_FAIL';
export const LOAD_LIST_SUCCESS = 'requestTypes/LOAD_LIST_SUCCESS';

export const LOAD_ITEM = 'requestTypes/LOAD_ITEM';
export const LOAD_ITEM_FAIL = 'requestTypes/LOAD_ITEM_FAIL';
export const LOAD_ITEM_SUCCESS = 'requestTypes/LOAD_ITEM_SUCCESS';

export const loadRequestTypes = () => {
    return resourceManager({
        method: 'get',
        url: '/requisitions/request-types',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_LIST }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_LIST_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_LIST_FAIL, error }),
    });
};

export const loadRequestType = (requestTypeId) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/request-types/${requestTypeId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_ITEM }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_ITEM_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_ITEM_FAIL, error }),
    });
};

export const createRequestType = (data) => {
    return resourceManager({
        method: 'post',
        requestOptions: { data },
        url: '/requisitions/request-types',
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Request type created!'));
            return result;
        },
    });
};

export const copyRequestType = (requestTypeId) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/request-types/${requestTypeId}/copy`,
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Request type copied!'));
            return result;
        },
    });
};
