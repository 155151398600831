import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'requestTypeEdit';

const fields = [
    'name',
    'endsInPurchaseOrder',
    'hideSectionAttachment',
    'hideSectionPurchase',
    'hideSectionVendor',
    'sectionInstructionsAdditional',
    'sectionInstructionsAttachment',
    'sectionInstructionsGeneral',
    'sectionInstructionsPurchase',
    'sectionInstructionsVendor',
    'sectionNameAdditional',
    'sectionNameAttachment',
    'sectionNameGeneral',
    'sectionNamePurchase',
    'sectionNameVendor',
];

export const fieldNames = listToDict(fields);

export const sections = [
    'formSettings',
    'general',
    'purchase',
    'vendor',
    'additional',
    'attachments',
];

export const sectionNames = listToDict(sections);
