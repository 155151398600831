import { gql } from 'graphql-request';

export const getAllCustomFields = gql`
    query {
        getAllCustomFields {
            id
            updated_at
            shortTitle
            name
            status
        }
    }
`;

export const getCustomField = gql`
    query ($id: ID!) {
        getCustomField(id: $id) {
            id
            updated_at
            created_at
            name
            status
            fieldType
            shortTitle
            instructionsText
            instructionsMode
            characterLimit
            numberKind
            enumOptions
        }
        getCustomFieldLogs(customFieldId: $id) {
            id
            actionType
            date
        }
        getCustomFieldClientForms(customFieldId: $id) {
            clientFormId
            clientFormType
        }
    }
`;

export const createCustomField = gql`
    mutation (
        $name: String!
        $fieldType: String!
        $shortTitle: String
        $status: Status
        $instructionsText: String
        $instructionsMode: String
        $characterLimit: String
        $numberKind: String
        $enumOptions: [String]
    ) {
        createCustomField(
            fieldType: $fieldType
            name: $name
            shortTitle: $shortTitle
            status: $status
            instructionsText: $instructionsText
            instructionsMode: $instructionsMode
            characterLimit: $characterLimit
            numberKind: $numberKind
            enumOptions: $enumOptions
        ) {
            id
        }
    }
`;

export const editCustomField = gql`
    mutation (
        $id: ID!
        $name: String
        $shortTitle: String
        $status: Status
        $instructionsText: String
        $instructionsMode: String
        $characterLimit: String
        $numberKind: String
        $enumOptions: [String]
    ) {
        editCustomField(
            id: $id
            name: $name
            shortTitle: $shortTitle
            status: $status
            instructionsText: $instructionsText
            instructionsMode: $instructionsMode
            characterLimit: $characterLimit
            numberKind: $numberKind
            enumOptions: $enumOptions
        ) {
            id
        }
    }
`;
