import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { Typography } from '@mui/material';

import { Box, Button, InputAdornment, TextField, TablePagination } from '@og-pro/ui';

import { Add as AddIcon } from '@mui/icons-material';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { capitalize, chunk } from 'lodash';

import { LoadingError, LoadingSpinner, NotFound } from '../../../../components';

import { CustomFieldsTable } from './CustomFieldsTable';

import CustomFieldsModal from './CustomFieldsModal';
import { useListCustomFields } from '../../../../lib/customFormService/useListCustomFields';

const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });

/**
 * Shapes API response for table
 * @param {object} customField API response, see CustomField graphql
 * @returns {object} row formatted for table
 */
function formatCustomFieldRow(customField) {
    return {
        customFieldId: customField.id,
        fieldName: customField.name,
        reportName: customField.shortTitle,
        lastUpdated: dateFormatter.format(new Date(parseInt(customField.updated_at, 10))),
        status: capitalize(customField.status),
    };
}

function createColumn(title, alignment, style) {
    return { title, alignment, style };
}

const cols = [
    createColumn('Field Name', 'left', 'h4'),
    createColumn('Report Name', 'left', 'h4'),
    createColumn('Last Updated', 'left', 'h4'),
    createColumn('Status', 'left', 'h4'),
];

export const CustomFieldsLibrary = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showModal, setShowModal] = useState(false);

    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const { isLoading, isError, error, data } = useListCustomFields();

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid user access to this page if the feature is disabled
    if (!enableCustomFields) {
        return <NotFound />;
    }
    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (isError) {
        return <LoadingError error={error.message} useOpenGovStyle />;
    }

    const totalFieldCount = data.getAllCustomFields.length;
    const dataPages = chunk(data.getAllCustomFields, rowsPerPage);

    const rows = (dataPages[page] || []).map(formatCustomFieldRow);

    return (
        <Box>
            <Typography component="h1" gutterBottom variant="h4">
                Library of Custom Fields
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment aria-label="search icon" position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    aria-label="Search Custom Fields"
                    placeholder="Search by Question"
                    sx={{
                        padding: 0,
                        '& .MuiInputBase-root': {
                            margin: 0,
                            height: '34px',
                        },
                        minWidth: '40%',
                    }}
                    type="text"
                />
                <Button color="primary" onClick={handleOpenModal} variant="contained">
                    <AddIcon fontSize="medium" /> New Custom Field
                </Button>
            </Box>
            {showModal && <CustomFieldsModal onClose={handleCloseModal} open={showModal} />}
            <CustomFieldsTable cols={cols} rows={rows} />
            <TablePagination
                component="div"
                count={totalFieldCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                slotProps={{
                    select: {
                        inputProps: { 'aria-label': 'rows per page' }, // Accessibility fix
                        native: true,
                    },
                }}
                sx={{
                    '& p': {
                        margin: '0',
                    },
                    '& .MuiSelect-icon': {
                        marginRight: '-4px',
                    },
                }}
            />
        </Box>
    );
};
