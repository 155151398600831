import React from 'react';
import { reduxForm } from 'redux-form';
import { NoSsr } from '@mui/material';

import { useParams } from 'react-router-dom';

import {
    customFieldFields,
    fieldTypes,
    yesNoOptions,
} from '@og-pro/shared-config/customFormService/customField';

import { formConfig } from './Form/form';
import { useLoadCustomField } from '../../../../lib/customFormService/useLoadCustomField';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { CreateCustomFieldProvider } from './context';

// Fetches data from hooks and passes to the reduxForm wrapped component.
// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withCustomPropsToReduxForm = (ReduxFormWrappedComponent) => {
    return (props) => {
        // `initialValues` is dynamically set by the current customField value, which is why it is
        // loaded here instead of in `formConfig`
        const { customFieldId, fieldType } = useParams();
        const { data, isLoading, isError, error } = useLoadCustomField(customFieldId);

        if (!customFieldId) {
            const enumOptionsInitialValue = {
                [customFieldFields.ENUM_OPTIONS]: ['', '', ''],
            };

            const yesNoEnumOptions = {
                enumOptions: yesNoOptions,
            };

            const checkboxInitialValue = {
                [customFieldFields.ENUM_OPTIONS]: [''],
            };

            // Create new field, not edit existing field
            const reduxFormProps = {
                initialValues: {
                    [customFieldFields.FIELD_TYPE]: fieldType,
                    ...((fieldType === fieldTypes.DROPDOWN ||
                        fieldType === fieldTypes.MULTIPLE_CHOICE ||
                        fieldType === fieldTypes.SINGLE_CHOICE) &&
                        enumOptionsInitialValue),
                    ...(fieldType === fieldTypes.YES_NO && yesNoEnumOptions),
                    ...(fieldType === fieldTypes.CHECKBOX && checkboxInitialValue),
                },
            };
            return (
                <NoSsr>
                    <CreateCustomFieldProvider>
                        <ReduxFormWrappedComponent {...props} {...reduxFormProps} />
                    </CreateCustomFieldProvider>
                </NoSsr>
            );
        }

        if (isLoading) {
            return <LoadingSpinner useOpenGovStyle />;
        }

        if (isError) {
            return <LoadingError error={error.message} useOpenGovStyle />;
        }

        const reduxFormProps = {
            initialValues: data.getCustomField || {},
        };

        return (
            <NoSsr>
                <CreateCustomFieldProvider>
                    <ReduxFormWrappedComponent {...props} {...reduxFormProps} />
                </CreateCustomFieldProvider>
            </NoSsr>
        );
    };
};

// Wraps a component with the redux-form decorator configured for the `requisitionsCreateCustomField` form
export const withCreateCustomFieldForm = () => (WrappedComponent) =>
    withCustomPropsToReduxForm(reduxForm(formConfig)(WrappedComponent));
