import React from 'react';

import { Field } from 'redux-form';

import { ReduxFormRadioGroup } from '@og-pro/ui';

import {
    numberKindsArray,
    NUMBER_KIND_FIELD,
} from '@og-pro/shared-config/customFormService/customField';

const ConnectedNumberOptions = () => {
    return (
        <Field
            component={ReduxFormRadioGroup}
            defaultSelected
            label="Type of Number *"
            name={NUMBER_KIND_FIELD}
            options={numberKindsArray}
            showValidation
        />
    );
};

export const NumberOptions = ConnectedNumberOptions;
