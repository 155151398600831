import { fieldNames } from './constants';

const {
    HIDE_SECTION_ATTACHMENT,
    HIDE_SECTION_PURCHASE,
    HIDE_SECTION_VENDOR,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_ATTACHMENT,
    SECTION_NAME_GENERAL,
    SECTION_NAME_PURCHASE,
    SECTION_NAME_VENDOR,
} = fieldNames;

export const validate = (values) => {
    const errors = {};

    [SECTION_NAME_ADDITIONAL, SECTION_NAME_GENERAL].forEach((sectionName) => {
        if (!values[sectionName]?.trim()) {
            errors[sectionName] = 'Section name is required';
        }
    });

    [
        [SECTION_NAME_ATTACHMENT, HIDE_SECTION_ATTACHMENT],
        [SECTION_NAME_PURCHASE, HIDE_SECTION_PURCHASE],
        [SECTION_NAME_VENDOR, HIDE_SECTION_VENDOR],
    ].forEach(([sectionName, isHidden]) => {
        if (!isHidden && !values[sectionName]?.trim()) {
            errors[sectionName] = 'Section name is required';
        }
    });

    [
        SECTION_NAME_ADDITIONAL,
        SECTION_NAME_ATTACHMENT,
        SECTION_NAME_GENERAL,
        SECTION_NAME_PURCHASE,
        SECTION_NAME_VENDOR,
    ].forEach((sectionName) => {
        if (values[sectionName]?.length > 256) {
            errors[sectionName] = 'Section name is too long';
        }
    });

    return errors;
};
