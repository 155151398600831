import { Settings as SettingsIcon } from '@mui/icons-material';
import { Box, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import { containerStyle, groupHeaderStyle, headerStyle, iconStyle } from './styles';
import { SectionSettings, SwitchWithLabel } from '../components';
import { fieldNames, form } from '../../constants';

const {
    ENDS_IN_PURCHASE_ORDER,
    HIDE_SECTION_ATTACHMENT,
    HIDE_SECTION_PURCHASE,
    HIDE_SECTION_VENDOR,
} = fieldNames;

export const FormSettings = ({ disabled }) => {
    const dispatch = useDispatch();
    const endsInPurchaseOrderFormValue = useSelector((state) =>
        formValueSelector(form)(state, ENDS_IN_PURCHASE_ORDER)
    );

    useEffect(() => {
        if (endsInPurchaseOrderFormValue) {
            dispatch(change(form, HIDE_SECTION_PURCHASE, false));
            dispatch(change(form, HIDE_SECTION_VENDOR, false));
        }
    }, [endsInPurchaseOrderFormValue, dispatch]);

    const TitleIcon = <SettingsIcon sx={iconStyle} />;

    return (
        <SectionSettings TitleIcon={TitleIcon} headerSx={headerStyle} title="Form Settings">
            <Box sx={containerStyle}>
                <Box>
                    <Typography sx={groupHeaderStyle} variant="h4">
                        General
                    </Typography>
                    <Field
                        component={SwitchWithLabel}
                        disabled={disabled}
                        label="Ends in a Purchase Order"
                        name={ENDS_IN_PURCHASE_ORDER}
                    />
                </Box>
                <Box>
                    <Typography sx={groupHeaderStyle} variant="h4">
                        Form Sections
                    </Typography>
                    <Field
                        component={SwitchWithLabel}
                        disabled={endsInPurchaseOrderFormValue || disabled}
                        invertSwitch
                        label="Include Purchase Details"
                        name={HIDE_SECTION_PURCHASE}
                    />
                    <Field
                        component={SwitchWithLabel}
                        disabled={endsInPurchaseOrderFormValue || disabled}
                        invertSwitch
                        label="Include Vendor Selection"
                        name={HIDE_SECTION_VENDOR}
                    />
                    <Field
                        component={SwitchWithLabel}
                        disabled={disabled}
                        invertSwitch
                        label="Include Attachments"
                        name={HIDE_SECTION_ATTACHMENT}
                    />
                </Box>
            </Box>
        </SectionSettings>
    );
};

FormSettings.propTypes = {
    disabled: PropTypes.bool,
};
