import qs from 'qs';
import { findLast, get, meanBy, orderBy, sortBy } from 'lodash';
import moment from 'moment';
import { formValueSelector, isValid } from 'redux-form';
import { createSelector } from 'reselect';
import { v4 as UUIDv4 } from 'uuid';

import { buildMap } from '@og-pro/shared-config/helpers';

import {
    builderProjectStatuses,
    getBuilderDisplayName as getBuilderDisplayNameHelper,
    projectStatusesDict,
    projectTypesDict,
    sourcingProjectStatuses,
} from '@og-pro/shared-config/projects';

import { timelineDates, projectTimelineConfigurations } from '@og-pro/shared-config/timelines';

import { userStatusTypes } from '@og-pro/shared-config/users';

import { filterTypesDict } from '@og-pro/shared-config/filters';

import { isAuctionLive } from '@og-pro/shared-config/reverseAuctions/util';

import { TIMELINE_CONFIG, TIMELINES } from '../constants';
import { form as builderCreateForm } from '../ProjectCreate/constants';
import {
    generateProjectSectionTagOptions,
    getContractJS,
    getIsAuctionEnded,
    getUserJS,
    isInitialClientLoaded,
} from '../../selectors';
import { buildCriteriaMap, buildSignatureMap } from '../../../reducers/helpers/projectCreateHelper';
import { normalizeProject } from '../../../reducers/utils';

import { getSDv2WritingSections } from '../selectors';
import { deserializeFilterQuery } from '../../../helpers';

const { DRAFT, REQUEST_DRAFT, REQUEST_REVIEW, REVIEW } = projectStatusesDict;

const { PURCHASE } = projectTypesDict;
const { CONTRACT, PROJECT } = filterTypesDict;

const { ACTIVE, INVITED, RESET } = userStatusTypes;

const getApprovalsList = (state) => state.approvals.get('list');
const getApprovalWorkflows = (state) => state.adminGovernment.get('approvalWorkflows');
const getAgreements = (state) => state.govProjects.get('agreements');
const getProjectStandardDoc = (state) => state.govProjects.get('standardDocument');
const getStandardDocuments = (state) => state.adminGovernment.get('standardDocuments');
const getCustomCategories = (state) => state.category.get('customCategories');
const getCommentThreads = (state) => state.govComments.get('comments');
const getPathname = (_state, props) => props.location.pathname;
const getProject = (state) => state.govProjects.get('selectedProject');
const getRawQueryParams = (_state, props) => props.location.query;
export const getBaseProjectPath = (_state, props) => {
    const { governmentId, projectId } = props.params;
    return `/governments/${governmentId}/projects/${projectId}`;
};
const getProposalVendor = (state) => state.govProposals.get('vendor');
const getTemplates = (state) => state.adminGovernment.get('templates');

export const hasAwardPendingProjectStatus = (state) =>
    !!state.auth.getIn(['user', 'government', 'usesAwardPending']);

export const hasAutoAddendaSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasAutoAddenda']);
export const hasContractingSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasContracting']);
export const hasDocBuilderSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasDocBuilder']);
export const hasEvaluationSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasEvaluation']);
export const hasUserRolesSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasPermissionRoles']);
export const hasIntakeSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasIntake']);
export const hasSourcingSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasSourcing']);
export const hasRequisitionSubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasRequisition']);
export const hasRequisitionOnlySubscription = (state) =>
    !!state.auth.getIn(['user', 'government', 'hasRequisitionOnly']);
export const patchingSettings = (state) => !!state.adminGovernment.get('patchingSettings');

// Government configuration for determining if category selection is part of project creation
export const usesCategorySelect = (state) =>
    !!state.auth.getIn(['user', 'government', 'usesCategorySelect']);

// Government configuration for determining if project ID is required when setting up a project
export const isProjectIdRequired = (state) =>
    !!state.auth.getIn(['user', 'government', 'isProjectIdRequired']);

export const internallyPublicContractsData = (state) =>
    !!state.auth.getIn(['user', 'government', 'internallyPublicContracts']);

export const aiEnablementData = (state) => {
    const value = state.auth.getIn(['user', 'government', 'aiEnablementData']);
    return value ? value.toJS() : null;
};

export const questionnaireConditionalEnabled = (state) =>
    !!state.auth.getIn(['user', 'government', 'questionnaireConditionalEnabled']);

export const getHideClosedProjectsSettings = (state) => {
    const user = getUserJS(state);

    const { hidePublicClosedProjectsLength, hidePublicClosedProjectsUnit } = user.government;

    return { hidePublicClosedProjectsLength, hidePublicClosedProjectsUnit };
};

export const usesDocxHeadingFormatters = (state) =>
    !!state.auth.getIn(['user', 'government', 'usesDocxHeadingFormatters']);

export const getDocxHeadingFormatters = (state) => {
    const value = state.exportProject.get('docxHeadingFormatters');

    return value ? value.toJS() : null;
};

// Government configuration for determining if budget data is required when setting up a project
export const isBudgetRequired = (state) =>
    !!state.auth.getIn(['user', 'government', 'isBudgetRequired']);

// Government configuration for determining if budget data is collected when setting up a project
export const isBudgetUsed = (state) => !!state.auth.getIn(['user', 'government', 'isBudgetUsed']);

export const getProjectJS = createSelector([getProject], (rawProject) => {
    if (rawProject) {
        return rawProject.toJS();
    }
});

export const getProjectStandardDocJS = createSelector([getProjectStandardDoc], (rawDocument) => {
    if (rawDocument) {
        return rawDocument.toJS();
    }
    return null;
});

export const getPortalUrl = createSelector([getUserJS], (user) => {
    const { code } = user.government;
    return `${process.env.SITE_URL}/portal/${code}`;
});

export const getPublicUrl = createSelector([getProjectJS, getPortalUrl], (project, portalUrl) => {
    if (project) {
        return `${portalUrl}/projects/${project.id}`;
    }
});

export const getContractModulePath = (_state, props) => {
    const { governmentId, projectId } = props.params;

    if (projectId) {
        const projectPath = getBaseProjectPath(_state, props);
        return `${projectPath}/contracts`;
    }

    return `/governments/${governmentId}/contracts`;
};

export const getContractPath = (_state, props) => {
    const { contractId } = props.params;
    const contractModulePath = getContractModulePath(_state, props);
    return `${contractModulePath}/${contractId}`;
};

const getSDv2WritingPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    let subpath = 'builder';

    if (props.isIntake) {
        subpath = 'intake';
    }

    if (props.location && props.location.query?.section) {
        const sections = getSDv2WritingSections(state);

        const sectionId = parseInt(props.location.query?.section, 10);

        if (!Number.isNaN(sectionId)) {
            const selectedSectionIndex = sections.findIndex((s) => s.id === sectionId);

            if (selectedSectionIndex !== -1) {
                return `${projectPath}/${subpath}/create/document-editor?activeSection=${selectedSectionIndex}`;
            }
        }
    }

    if (props.location?.pathname?.endsWith('setup')) {
        const sectionId = !Number.isNaN(parseInt(props.activeSection, 10))
            ? parseInt(props.activeSection, 10)
            : 1;
        return `${projectPath}/${subpath}/create/project-properties?activeSection=${sectionId}`;
    }

    if (props.location?.pathname?.endsWith('attachments')) {
        return `${projectPath}/${subpath}/create/attachments`;
    }

    if (props.location?.pathname?.endsWith('signatures')) {
        return `${projectPath}/${subpath}/create/signatures`;
    }

    if (
        props.location?.pathname?.endsWith('approvals') ||
        props.location?.pathname?.endsWith('comments') ||
        props.location?.pathname?.endsWith('revisions/versions')
    ) {
        return `${projectPath}/${subpath}/create/review-checklist`;
    }

    return `${projectPath}/${subpath}/create/document-editor`;
};

export const getIntakePath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/intake`;
};

export const getIntakeWritingPath = (state, props) => {
    return getSDv2WritingPath(state, { ...props, isIntake: true });
};

export const getWritingPath = (state, props) => {
    return getSDv2WritingPath(state, props);
};

const getBaseReviewPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/builder`;
};

const getSDv2ReviewPath = (state, props) => {
    if (props.isIntake) {
        return getIntakePath(state, props);
    }

    if (props.location?.pathname?.endsWith('review-checklist')) {
        return `${getBaseReviewPath(state, props)}/approvals`;
    }

    if (props.location?.pathname?.endsWith('attachments')) {
        return `${getBaseReviewPath(state, props)}/attachments`;
    }

    if (props.location?.pathname?.endsWith('signatures')) {
        return `${getBaseReviewPath(state, props)}/signatures`;
    }

    if (props.location?.pathname?.endsWith('approvals-editor')) {
        return `${getBaseReviewPath(state, props)}/approvals`;
    }

    if (
        props.location?.pathname?.endsWith('project-properties') &&
        props.location?.query?.activeSection === '1'
    ) {
        return `${getBaseReviewPath(state, props)}/setup`;
    }

    return getBaseReviewPath(state, props);
};

export const getReviewPath = (state, props) => {
    // this function is also used to get the path for the review page tabs
    // so if we are not inside a "create" we can just return the base path to
    // not mess the tabs links
    if (!props.location?.pathname?.includes('create')) {
        return getBaseReviewPath(state, props);
    }

    return getSDv2ReviewPath(state, props);
};

export const getPostingPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/sourcing`;
};

export const getReverseAuctionPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/reverse-auction`;
};

export const getEvaluationWritingPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/evaluation/create`;
};

export const getEvaluationPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/evaluation`;
};

export const getDashboardPath = (state, props) => {
    const projectPath = getBaseProjectPath(state, props);
    return `${projectPath}/manage`;
};

export const getIntakeDashboardPath = createSelector(
    [getProjectJS, getDashboardPath],
    (project, dashboardPath) => {
        // Use the intake project as the dashboard project if available
        if (project && project.intakeProjectId) {
            return `/governments/${project.government_id}/projects/${project.intakeProjectId}/manage`;
        }
        return dashboardPath;
    }
);

export const getDocBuilderProjectDashboardPath = createSelector(
    [getProjectJS, getDashboardPath],
    (project, dashboardPath) => {
        if (project && project.docBuilderProject) {
            return `/governments/${project.government_id}/projects/${project.docBuilderProject.id}/manage`;
        }
        return dashboardPath;
    }
);

export const getDocumentReviewPath = createSelector(
    [getPathname, getIntakePath, getReviewPath],
    (pathname, intakePath, reviewPath) => {
        if (pathname.match(new RegExp(`^${intakePath}`))) {
            return intakePath;
        }
        return reviewPath;
    }
);

export const getDocumentWritingPath = createSelector(
    [getPathname, getIntakePath, getIntakeWritingPath, getWritingPath],
    (pathname, intakePath, intakeWritingPath, writingPath) => {
        if (pathname.match(new RegExp(`^${intakePath}`))) {
            return intakeWritingPath;
        }
        return writingPath;
    }
);

export const getTagsManagementPath = createSelector([getUserJS], (user) => {
    return `/governments/${user.government_id}/admin/tags`;
});

export const isIntakeDraftPage = createSelector(
    [getIntakeWritingPath, getPathname],
    (intakeWritingPath, pathname) => {
        return !!pathname.match(new RegExp(`^${intakeWritingPath}`));
    }
);

export const isBuilderDraftPage = createSelector(
    [getWritingPath, getPathname],
    (writingPath, pathname) => {
        return !!pathname.match(new RegExp(`^${writingPath.replace('/document-editor', '')}`));
    }
);

export const isPostDraftPage = createSelector(
    [getPostingPath, getPathname],
    (postPath, pathname) => {
        return !!pathname.match(new RegExp(`^${postPath}/create`));
    }
);

export const isEvaluationDraftPage = createSelector(
    [getEvaluationWritingPath, getPathname],
    (writingPath, pathname) => {
        return !!pathname.match(new RegExp(`^${writingPath}`));
    }
);

export const getWritingProjectJS = createSelector([getProjectJS], (project) => {
    if (project) {
        return buildSignatureMap(buildCriteriaMap(project));
    }
});

export const getReviewProjectJS = createSelector([getProjectJS], (project) => {
    if (project) {
        return normalizeProject(project);
    }
});

export const getEvaluationProjectJS = createSelector([getProjectJS], (project) => {
    if (project && project.evaluation) {
        return project;
    }
});

export const getCollaboratorsJS = createSelector([getProjectJS], (project) => {
    if (project && project.userPermissions) {
        const activeUsers = project.userPermissions.filter((user) => {
            return user.status === ACTIVE || user.status === RESET || user.status === INVITED;
        });
        return orderBy(activeUsers, 'projectPermissions.created_at', ['desc']);
    }
    return [];
});

export const isSubscribed = createSelector([getProjectJS, getUserJS], (project, user) => {
    if (project && user) {
        return (project.followers || []).some((follower) => follower.id === user.id);
    }
    return false;
});

export const getCustomCategoriesJS = createSelector([getCustomCategories], (customCategories) =>
    customCategories.toJS()
);

export const getProjectModuleDataJS = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.moduleData;
    }
});

export const getGovernmentId = createSelector([getUserJS], (user) => {
    if (user) {
        return user.government_id;
    }
});

export const getProposalVendorJS = createSelector([getProposalVendor], (rawVendor) => {
    if (rawVendor) {
        return rawVendor.toJS();
    }
});

// Builds a map of criteria items indexed by ID for quick criteria lookup
export const getCriteriaMap = createSelector([getProjectJS], (project) => {
    if (project) {
        return buildMap(project.criteria, 'id');
    }
});

// Builds a map of project subsection items indexed by project subsection ID for quick lookup
export const getProjectSubsectionsMap = createSelector([getProjectJS], (project) => {
    if (project) {
        return project.projectSections.reduce((projectSubsectionsMap, projectSection) => {
            return {
                ...projectSubsectionsMap,
                ...buildMap(projectSection.projectSubsections, 'id'),
            };
        }, {});
    }
});

const getPublishedTemplates = createSelector([getTemplates], (templates) => {
    return templates.toJS().filter((template) => template.isPublished);
});

export const getActiveTemplates = createSelector([getPublishedTemplates], (templates) => {
    return templates.filter((template) => !template.isSpecial && !template.archived);
});

export const getTemplateDocBuilderSelectOptions = createSelector(
    [getPublishedTemplates],
    (templates) => {
        return templates
            .filter((template) => template.projectType !== PURCHASE)
            .map((template) => {
                return {
                    value: template.id,
                    label: template.title,
                };
            });
    }
);

export const getTemplatePurchaseSelectOptions = createSelector(
    [getPublishedTemplates],
    (templates) => {
        return templates
            .filter((template) => template.projectType === PURCHASE)
            .map((template) => {
                return {
                    value: template.id,
                    label: template.title,
                };
            });
    }
);

export const getNonSpecialTemplatePurchaseSelectOptions = createSelector(
    [getPublishedTemplates],
    (templates) => {
        return templates
            .filter((template) => template.projectType === PURCHASE && !template.isSpecial)
            .map((template) => {
                return {
                    value: template.id,
                    label: template.title,
                };
            });
    }
);

export const getProjectSectionTagOptions = createSelector(
    [getProjectJS],
    generateProjectSectionTagOptions
);

export const getApprovalsListJS = createSelector([getApprovalsList], (rawApprovals) => {
    if (!rawApprovals) {
        return [];
    }
    return rawApprovals.toJS();
});

export const getUserApprovalJS = createSelector(
    [getProjectJS, getUserJS, getApprovalsListJS],
    (project, user, approvals) => {
        if (project && user) {
            if (project.isIntake && project.status !== REQUEST_REVIEW) {
                return null;
            }

            if (!project.isIntake && project.status !== REVIEW) {
                return null;
            }

            const projectApprovalList = [];

            approvals.forEach((reviewStep) => {
                reviewStep.projectApprovals.forEach((approval) => {
                    projectApprovalList.push(approval);
                });
            });

            return projectApprovalList.find((approval) => approval.user_id === user.id);
        }
    }
);

export const getCommentThreadsJS = createSelector([getCommentThreads], (rawCommentThreads) => {
    if (rawCommentThreads) {
        return rawCommentThreads.toJS();
    }
    return {};
});

export const getBuilderDisplayName = createSelector([getProjectJS], (project) => {
    return getBuilderDisplayNameHelper(project);
});

export const getApprovalWorkflowsJS = createSelector(
    [getApprovalWorkflows],
    (rawApprovalWorkflows) => {
        return rawApprovalWorkflows.toJS();
    }
);

export const getStandardDocumentsJS = createSelector([getStandardDocuments], (rawDocuments) => {
    return rawDocuments.toJS();
});

export const isBuilderFormValid = (state) =>
    isInitialClientLoaded(state) && isValid(builderCreateForm)(state);
export const isBuilderSaveValid = createSelector(
    [isBuilderFormValid, getProjectJS],
    (isFormValid, project) => {
        // Saving is always valid in the draft state
        if (project && [REQUEST_DRAFT, DRAFT].includes(project.status)) {
            return true;
        }

        return isFormValid;
    }
);

/**
 * EVALUATION PHASE SELECTORS
 *
 * IMPORTANT: All of these selectors needs `location` via the props parameter
 */

const getQueryPhaseParams = (state, props) => get(props, 'location.query.phase');
const getEvaluatorIdParams = (state, props) => props.params.evaluatorId;

export const getQueryPhase = createSelector([getQueryPhaseParams], (queryPhaseParams) => {
    const parsedQueryPhase = Number.parseInt(queryPhaseParams, 10);
    if (!Number.isNaN(parsedQueryPhase)) {
        return parsedQueryPhase;
    }
});

export const getEvaluationAuditData = createSelector(
    [getEvaluationProjectJS, getQueryPhase],
    (project, queryPhase) => {
        if (!project || !queryPhase || project.evaluation.phaseNumber === queryPhase) {
            return undefined;
        }
        const evaluationAudit = project.evaluationAudits.find(
            (audit) => audit.data.evaluation.phaseNumber === queryPhase
        );
        if (!evaluationAudit) {
            return undefined;
        }
        return evaluationAudit.data;
    }
);

export const isViewingPastPhase = createSelector(
    [getEvaluationAuditData],
    (evaluationAudit) => !!evaluationAudit
);

export const getCurrentEvaluation = createSelector(
    [getEvaluationProjectJS, getEvaluationAuditData],
    (project, evaluationAudit) => {
        return get(evaluationAudit, 'evaluation') || get(project, 'evaluation');
    }
);

export const getCurrentScoredProposals = createSelector(
    [getEvaluationProjectJS, getEvaluationAuditData],
    (project, evaluationAudit) => {
        return get(evaluationAudit, 'scoredProposals') || get(project, 'scoredProposals') || [];
    }
);

export const getEvaluator = createSelector(
    [getCurrentEvaluation, getUserJS, getEvaluatorIdParams],
    (evaluation, user, evaluatorIdParams) => {
        if (evaluatorIdParams && evaluation) {
            const evaluatorId = Number.parseInt(evaluatorIdParams, 10);
            return evaluation.evaluators.find((evaluator) => evaluator.id === evaluatorId);
        }
        return user;
    }
);

export const isEvaluator = createSelector(
    [getCurrentEvaluation, getEvaluator],
    (evaluation, evaluator) => {
        if (evaluation && evaluator) {
            return evaluation.evaluators.some((user) => user.id === evaluator.id);
        }
        return false;
    }
);

export const getAgreementsJS = createSelector(
    [getAgreements, getEvaluationAuditData],
    (rawAgreements, evaluationAudit) => {
        return get(evaluationAudit, 'agreements') || (rawAgreements && rawAgreements.toJS()) || [];
    }
);

export const mustAcceptProposalViewerAgreement = createSelector(
    [getProjectJS, getUserJS, getAgreementsJS],
    (project, user, agreements) => {
        // Only projects with a standard document need to be agreed to
        if (!project.standard_document_id) {
            return false;
        }
        return agreements.every((agreement) => agreement.user_id !== user.id);
    }
);

export const getContractReviewsJS = createSelector([getContractJS], (contract) => {
    if (contract && contract.contractReviews) {
        return contract.contractReviews.filter((contractReview) => contractReview.isPublished);
    }
    return [];
});

export const getContractAggregateRating = createSelector(
    [getContractReviewsJS],
    (contractReviews) => {
        if (contractReviews.length > 0) {
            const avg = meanBy(contractReviews, (contractReview) => contractReview.overallRating);
            return Math.round(avg * 100) / 100;
        }
    }
);

export const getContractComplaintsCounts = createSelector(
    [getContractReviewsJS],
    (contractReviews) => {
        let contractComplaintsTotal = 0;
        let contractComplaintsResolved = 0;
        let contractComplaintsUnresolved = 0;
        contractReviews.forEach((contractReview) => {
            contractReview.contractComplaints.forEach((contractComplaint) => {
                contractComplaintsTotal++;
                if (contractComplaint.isResolved) {
                    contractComplaintsResolved++;
                } else {
                    contractComplaintsUnresolved++;
                }
            });
        });

        return {
            contractComplaintsTotal,
            contractComplaintsResolved,
            contractComplaintsUnresolved,
        };
    }
);

export const getWorkloadWeightStatuses = createSelector(
    [hasSourcingSubscription, hasEvaluationSubscription, hasAwardPendingProjectStatus],
    (hasSourcing, hasEvaluation, hasAwardPending) => {
        const statuses = [...builderProjectStatuses];

        if (hasSourcing) {
            statuses.push(...sourcingProjectStatuses);
        }

        if (hasEvaluation) {
            statuses.push(projectStatusesDict.EVALUATION);
        }

        if (hasAwardPending) {
            statuses.push(projectStatusesDict.AWARD_PENDING);
        }

        return statuses;
    }
);

const getProjectTimelineFormValues = (state, props) => {
    return formValueSelector(props.form)(state, TIMELINE_CONFIG, ...timelineDates);
};

const getDynamicTimelineFormValues = (state, props) => {
    return formValueSelector(props.form)(state, TIMELINES);
};

const getProjectTimelinesParser = (projectTimelineFormValues) => {
    const TODAY = new Date();
    TODAY.setHours(0, 0, 0, 0);

    let lastDate = TODAY;
    return projectTimelineConfigurations.map((configuration) => {
        // Keep track of the last date so it can be added
        const previousLastDate = lastDate;
        const timelineConfig = projectTimelineFormValues[TIMELINE_CONFIG] || {};
        const date = projectTimelineFormValues[configuration.projectField];
        if (date) {
            lastDate = date;
        }

        return {
            ...configuration,
            date,
            hasProposalDeadlineLocation:
                timelineConfig[configuration.hasProposalDeadlineLocationField],
            hasAuctionExtension: timelineConfig[configuration.hasAuctionExtensionField],
            isIncluded: timelineConfig[configuration.isIncludedField],
            lastDate: previousLastDate,
            preProposalAgendaUrl: timelineConfig[configuration.preProposalAgendaUrlField],
            orderById: timelineConfig[configuration.orderByIdField],
            title: timelineConfig[configuration.titleField] || configuration.title,
            uuid: configuration.projectField,
        };
    });
};

export const getProjectTimelines = createSelector(
    [getProjectTimelineFormValues],
    getProjectTimelinesParser
);

const getDynamicTimelinesParser = (dynamicTimelines) => {
    if (dynamicTimelines) {
        return dynamicTimelines.map((timeline, index) => ({
            ...timeline,
            index,
            isCustomDate: true,
        }));
    }
    return [];
};

export const getDynamicTimelines = createSelector(
    [getDynamicTimelineFormValues],
    getDynamicTimelinesParser
);

export const getTimelines = createSelector(
    [getProjectTimelines, getDynamicTimelines],
    (projectTimelines, dynamicTimelines) => {
        const timelines = [...projectTimelines, ...dynamicTimelines];

        return sortBy(timelines, 'orderById');
    }
);

export const getTimelinesWithoutForm = createSelector([getProjectJS], (project) => {
    const projectTimelineValues = {
        ...timelineDates,
        [TIMELINE_CONFIG]: project[TIMELINE_CONFIG],
    };
    const dynamicTimelineValues = project[TIMELINES];

    return sortBy(
        [
            ...getProjectTimelinesParser(projectTimelineValues),
            ...getDynamicTimelinesParser(dynamicTimelineValues),
        ],
        'orderById'
    );
});

export const getPostOpenEditableTimelines = createSelector(
    [getProjectJS, getTimelinesWithoutForm],
    (project, timelines) => {
        const deadlineValue = project.template.isReverseAuction
            ? project.auctionEndDate
            : project.proposalDeadline;
        const deadline = moment(deadlineValue);

        const filteredTimelines = timelines.filter((timeline) => {
            const value = timeline.projectField ? project[timeline.projectField] : timeline.date;

            return (
                (timeline.isIncluded || timeline.required || timeline.isCustomDate) &&
                (timeline.textDate || moment(value).isAfter(deadline, 'hour'))
            );
        });

        return filteredTimelines;
    }
);

export const getFilteredTimelines = createSelector(
    [getProjectTimelines, getDynamicTimelines],
    (projectTimelines, dynamicTimelines) => {
        const timelines = [...projectTimelines, ...dynamicTimelines];

        const filteredTimelines = timelines.filter((timelineEntry) => {
            return timelineEntry.date !== null && !timelineEntry.hasTextDate;
        });

        return sortBy(filteredTimelines, 'orderById');
    }
);

export const getNextDateToAdd = createSelector([getTimelines], (projectTimelines) => {
    const TODAY = new Date();
    TODAY.setHours(0, 0, 0, 0);

    const lastCompletedTimeline = findLast(projectTimelines, (timeline) => timeline.date);
    const nextDate = (lastCompletedTimeline && lastCompletedTimeline.date) || TODAY;

    return moment(nextDate).add(1, 'day').toDate();
});

export const parseRawQueryParams = (rawQueryParams, pathname) => {
    if (rawQueryParams) {
        const parsedQueryParams = qs.parse(rawQueryParams, { ignoreQueryPrefix: true });

        return {
            ...parsedQueryParams,
            // Overrides `page` and `filter` keys with deserialized values
            ...deserializeFilterQuery(
                parsedQueryParams,
                pathname.includes(PROJECT) ? PROJECT : CONTRACT
            ),
        };
    }

    return {};
};

export const getParsedQueryParams = createSelector(
    [getRawQueryParams, getPathname],
    parseRawQueryParams
);

export const getFilterFormValues = createSelector([getParsedQueryParams], (queryParams) => {
    const { filters } = queryParams;

    if (filters) {
        return {
            // Add in unique ID for handling form rendering
            filters: filters.map((filter) => ({ ...filter, localCreatingUUID: UUIDv4() })),
        };
    }
});

export const getIsAuctionLive = createSelector(
    [getIsAuctionEnded, getProjectJS],
    (isAuctionEnded, project) => {
        // `auctionEnded` gets set by the socket broadcast when auction ends
        if (isAuctionEnded) return false;

        if (project) {
            return isAuctionLive(project);
        }

        return false;
    }
);
