import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { statusTypes } from '@og-pro/shared-config/requestTypes';
import { Box, Button, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ButtonBar } from './ButtonBar';
import { Nav } from './Nav';
import { qaTagName } from '../../constants';
import { headerContainer } from '../../styles';
import { getRequestTypesPath } from '../../../selectors';
import { TemplateStatusLabel } from '../../../../../../components';

export const RequestTypeHeader = ({ requestType: { id, name, status } }) => {
    const requestTypesPath = useSelector(getRequestTypesPath);

    return (
        <Box sx={{ ...headerContainer, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', gap: 3 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Button
                        color="secondary"
                        component={Link}
                        qaTag={`${qaTagName}-backToRequestTypes`}
                        size="small"
                        startIcon={<ArrowBackIcon />}
                        to={requestTypesPath}
                        variant="text"
                    >
                        Back to Request Types
                    </Button>
                    <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, paddingTop: 0.5 }}>
                        <Typography variant="h1">{name || 'Untitled'}</Typography>
                        <TemplateStatusLabel
                            isArchived={status === statusTypes.ARCHIVED}
                            isPublished={status === statusTypes.PUBLISHED}
                            size="small"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
                <ButtonBar />
            </Box>
            <Nav requestTypeId={id} />
        </Box>
    );
};

RequestTypeHeader.propTypes = {
    requestType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        status: PropTypes.string.isRequired,
    }).isRequired,
};
