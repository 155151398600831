import { useCustomFormService } from './useCustomFormService';
import { getCustomField } from './queries';

/**
 * Load custom field if provided, otherwise does not load anything
 * @param {string | undefined} customFieldId id part from params, not parsed as int yet
 * @returns {object} react-query usequery return value
 */
export const useLoadCustomField = (customFieldId) => {
    return useCustomFormService({
        queryKey: 'getCustomField',
        query: getCustomField,
        enabled: customFieldId !== undefined,
        params: {
            id: parseInt(customFieldId, 10),
        },
    });
};
