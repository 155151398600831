import { NoSsr } from '@mui/material';
import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { form } from './constants';
import { validate } from './validate';
import { getRequestType } from '../../../../../selectors/govApp';

const formConfig = {
    form,
    enableReinitialize: true,
    validate,
};

// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withFormConfig = (ReduxFormWrappedComponent) => {
    return (props) => {
        const requestType = useSelector(getRequestType);

        const reduxFormProps = {
            initialValues: requestType,
        };

        return (
            <NoSsr>
                <ReduxFormWrappedComponent {...props} {...reduxFormProps} />;
            </NoSsr>
        );
    };
};

// Wraps a component with the redux-form decorator configured for the `requestTypeForm` form
export const withRequestTypeForm = () => (WrappedComponent) =>
    withFormConfig(reduxForm(formConfig)(WrappedComponent));
