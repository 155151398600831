import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getRequestTypesPath } from '../selectors';
import { copyRequestType as copyRequestTypeAction } from '../../../../actions/requestTypes';

const RequestTypeActionsContext = createContext();

/**
 * Custom hook to handle request type actions.
 *
 * @returns {Object} An object containing:
 * - `copyError` (string|null): Error message if copying fails.
 * - `copyRequestType` (function): Function to copy the request type.
 * - `disabled` (boolean): Indicates if any action is in process.
 * - `error` (string|null): Error message if any actions fail.
 * - `isCopying` (boolean): Indicates if the copy operation is in progress.
 */
export const useRequestTypeActions = () => {
    return useContext(RequestTypeActionsContext);
};

export const RequestTypeActionsProvider = ({ children, requestType }) => {
    const [isCopying, setIsCopying] = useState(false);
    const [copyError, setCopyError] = useState(null);

    const requestTypesPath = useSelector(getRequestTypesPath);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const copyRequestType = async () => {
        setIsCopying(true);
        setCopyError(null);

        try {
            const copiedRequestType = await dispatch(copyRequestTypeAction(requestType.id));
            setIsCopying(false);
            navigate(`${requestTypesPath}/${copiedRequestType.id}`);
        } catch (e) {
            setIsCopying(false);
            setCopyError(e?.message);
        }
    };

    const disabled = isCopying;
    const error = copyError;

    const value = {
        copyError,
        copyRequestType,
        disabled,
        error,
        isCopying,
    };

    return (
        <RequestTypeActionsContext.Provider value={value}>
            {children}
        </RequestTypeActionsContext.Provider>
    );
};

RequestTypeActionsProvider.propTypes = {
    children: PropTypes.node,
    requestType: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};
