// Kebab case on flags
export const FLAGS = {
    TEST_FLAG: 'pro-test-flag',
    VENDOR_DISCOVERY_AI: 'pro-vendor-discovery-ai',
    PLATFORM_PASSWORD_RESET: 'pro-platform-password-reset',
    ACCOUNT_SPLIT_AUTO_ADJUSTMENTS: 'pro-requisition-line-item-account-split-auto-adjustments',
    SHOW_SERVICE_CLOUD_CHAT_BOT: 'pro-show-service-cloud-chat-bot',
    ENABLE_MUI_TEXTAREA_AUTOSIZE: 'pro-enable-mui-textarea-autosize',
    ENABLE_REACT_TABLE_8: 'pro-enable-react-table-8',
    ATTACHMENTS_SCANNING: 'pro-4363-attachment-scanning',
    PUBLIC_VENDOR_SEARCH_PORTAL: 'pro-4968-public-vendor-search-portal',
    ALL_PROPOSALS_REPORT: 'pro-all-proposals-report',
    SHOW_CERTIFICATIONS_IMPORT: 'pro-4468-enable-vendor-certifications-import',
    DOING_BUSINESS_AS: 'pro-5274-doing-business-as-field',
    ENABLE_CONTRACT_DOCUMENTS_TAB: 'pro-6888-create-new-documents-tab-on-contracts',
    ENABLE_GLOBAL_NAV_GOV_USERS: 'pro-enable-global-nav',
    ENABLE_VENDOR_LISTS_UPDATE: 'pro-5282-updated-vendor-lists',
    ENABLE_SIGNATURE_SECTION: 'pro-enable-signature-section',
    ENABLE_PUBLIC_CONTRACTS_TAB: 'pro-5387-public-contracts-tab',
    ENABLE_HIDE_SECTION_NUMBERING: 'pro-enable-hide-section-numbering',
    ENABLE_SINGLE_SECTION_EXPORT: 'pro-5862-enable-single-section-export',
    ENABLE_SIGNATURES_TAB: 'pro-signatures-tab',
    ENABLE_NEW_INTERNAL_VENDOR_MANAGEMENT_TABLE: 'pro-enable-new-internal-vendor-management-table',
    ENABLE_CONTRACT_PACKAGE_COMPILER: 'pro-6522-contract-package-compiler',
    ENABLE_CUSTOM_FIELDS: 'pro-enable-custom-field-ui',
    ENABLE_MOVE_CONTRACTS_SUPPORT_TOGGLE_TO_SITE: 'pro-6755-move-contracts-support-toggle-to-site',
    ENABLE_DOCUSIGN_UI: 'pro-enable-docusign-ui',
    RECORDS_RETENTION_ENHANCEMENTS: 'pro-6359-records-retention-enhancements',
    EXPORT_BUTTON_V2: 'pro-6794-export-button-v-2',
};
