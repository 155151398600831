import PropTypes from 'prop-types';
import React from 'react';
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { useSearchParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { requisitionSortingOptionsNames } from '@og-pro/shared-config/requisitions';

import { RequisitionsListItem } from './RequisitionsListItem';
import { RequisitionDashboardEmptyState } from './EmptyState';
import { columns } from './constants';
import { columnStyles } from './styles';
import { getIsSearching } from '../selectors';
import { LoadingSpinner } from '../../../../../components';

const { ACTIVE_DATE } = requisitionSortingOptionsNames;

const defaultSearchParams = {
    sort: ACTIVE_DATE,
    sortDirection: 'DESC',
};

export const RequisitionsList = ({ requisitions }) => {
    const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
    const sortBy = searchParams.get('sort');
    const sortDirection = searchParams.get('sortDirection');

    const isSearching = useSelector(getIsSearching);

    if (isSearching) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (requisitions.length === 0) {
        return <RequisitionDashboardEmptyState />;
    }

    const sortHandler = (property) => {
        const isDesc = sortBy === property && sortDirection === 'DESC';

        searchParams.set('sort', property);
        searchParams.set('sortDirection', isDesc ? 'ASC' : 'DESC');
        setSearchParams(searchParams);
    };

    return (
        <TableContainer
            sx={{
                overflowX: 'auto',
                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                borderBottom: 0,
            }}
        >
            <MuiTable aria-label="Requests List">
                <TableHead>
                    <TableRow>
                        {Object.keys(columns).map((col) => {
                            const column = columns[col];
                            return (
                                <TableCell
                                    align={column.headAlign}
                                    key={col}
                                    style={{ minWidth: column.width }}
                                >
                                    {column.sortKey ? (
                                        <TableSortLabel
                                            active={sortBy === column.sortKey}
                                            direction={
                                                sortBy === column.sortKey
                                                    ? sortDirection.toLowerCase()
                                                    : 'desc'
                                            }
                                            onClick={() => sortHandler(column.sortKey)}
                                            sx={columnStyles}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        <Typography sx={columnStyles}>{column.label}</Typography>
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requisitions.map((requisition) => (
                        <RequisitionsListItem key={requisition.id} requisition={requisition} />
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

RequisitionsList.propTypes = {
    requisitions: PropTypes.array.isRequired,
};
