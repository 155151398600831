import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, ReduxFormTextField, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { containerStyle, iconStyle, titleStyle } from './styles';
import { qaTagName } from '../../../constants';

export const SectionTitleFormSection = ({
    disabled,
    sectionInstructionsField,
    sectionNameField,
}) => {
    return (
        <Box sx={containerStyle}>
            <Typography sx={titleStyle} variant="h4">
                <MenuIcon sx={iconStyle} />
                Title
            </Typography>
            <Field
                component={ReduxFormTextField}
                disabled={disabled}
                fullWidth
                label="Section Name*"
                name={sectionNameField}
                qaTag={`${qaTagName}-${sectionNameField}`}
            />
            <Field
                component={ReduxFormTextField}
                description="Optional secondary information intended to guide users on how to answer the question."
                disabled={disabled}
                fullWidth
                label="Instruction Text"
                name={sectionInstructionsField}
                qaTag={`${qaTagName}-${sectionInstructionsField}`}
            />
        </Box>
    );
};

SectionTitleFormSection.propTypes = {
    disabled: PropTypes.bool,
    sectionInstructionsField: PropTypes.string.isRequired,
    sectionNameField: PropTypes.string.isRequired,
};
