import { Box, Button, Grid, Typography } from '@og-pro/ui';
import { upperCase } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { sectionNames, sections } from './constants';
import { withRequestTypeForm } from './RequestTypeFormHOC';
import { SettingsStickySidebar } from './SettingsStickySidebar';
import { bodyContainer, formContainer } from '../styles';
import { getRequestType } from '../../../../../selectors/govApp';

const DEFAULT_SECTION = sectionNames.FORM_SETTINGS;

const ConnectedRequestTypeForm = () => {
    const [selectedSection, setSelectedSection] = React.useState(DEFAULT_SECTION);
    const requestType = useSelector(getRequestType);

    return (
        <>
            <Box sx={{ ...bodyContainer, paddingY: 2 }}>
                <Typography variant="h2">{requestType.name || 'Untitled'} Form</Typography>
            </Box>
            <Box sx={formContainer}>
                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                        {sections.map((section) => (
                            <Box key={section} sx={{ marginBottom: 1 }}>
                                <Button
                                    onClick={() => setSelectedSection(section)}
                                    variant={selectedSection === section ? 'contained' : 'outlined'}
                                >
                                    {upperCase(section)}
                                </Button>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SettingsStickySidebar
                            onClose={() => setSelectedSection(DEFAULT_SECTION)}
                            section={selectedSection}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export const RequestTypeForm = withRequestTypeForm()(ConnectedRequestTypeForm);
