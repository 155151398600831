import React from 'react';

import { Field } from 'redux-form';

import { ReduxFormTextField } from '@og-pro/ui';

import { ENUM_OPTIONS_FIELD } from '@og-pro/shared-config/customFormService/customField';

export const CheckboxOptions = () => {
    return (
        <Field
            component={ReduxFormTextField}
            label="Checkbox Text *"
            name={`${ENUM_OPTIONS_FIELD}[0]`}
        />
    );
};
