import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import {
    Box,
    Button,
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from '@og-pro/ui';
import React from 'react';

import { qaTagName } from '../../constants';
import { useRequestTypeActions } from '../../RequestTypeActionsContext';

export const ButtonBar = () => {
    const { copyRequestType, disabled, error } = useRequestTypeActions();

    return (
        <Box>
            <Box sx={{ alignItems: 'flex-start', display: 'flex', gap: 1 }}>
                <DropdownMenu
                    color="secondary"
                    disabled={disabled}
                    label="Actions"
                    variant="outlined"
                >
                    <ListItem disablePadding>
                        <ListItemButton disabled={disabled} onClick={copyRequestType}>
                            <ListItemText id="requestType-copy" primary="Copy Request Type" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton disabled={disabled} onClick={() => {}}>
                            <ListItemText id="requestType-dropdown-b" primary="Action 2" />
                        </ListItemButton>
                    </ListItem>
                </DropdownMenu>
                <Button color="primary" qaTag={`${qaTagName}-setLive`} variant="outlined">
                    Set Request Live
                </Button>
                <Button color="primary" qaTag={`${qaTagName}-save`} variant="contained">
                    Save Draft
                </Button>
            </Box>
            {error && (
                <Typography
                    sx={{
                        color: capitalDesignTokens.semanticColors.foreground.errorSmall,
                        marginTop: 0.5,
                        textAlign: 'right',
                    }}
                >
                    {error}
                </Typography>
            )}
        </Box>
    );
};
