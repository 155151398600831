const { listToDict, buildCustomSortOrderMap } = require('../helpers');

exports.projectDateFilterTypes = ['after', 'before', 'between'];

exports.projectDateFilterTypesDict = listToDict(exports.projectDateFilterTypes);

exports.projectFilterTypes = [
    'department_id',
    'financialId',
    'status',
    'template_id',
    'title',
    'isPaused',
    'comingSoon',
    'retention_code_id',
    // Dates
    'created_at',
    'contractorSelectedDate',
    'postedAt',
    'postScheduledAt',
    'preProposalDate',
    'proposalDeadline',
    'qaDeadline',
    'qaResponseDeadline',
    'releaseProjectDate',
];

exports.projectFilterTypesDict = {
    ...listToDict(exports.projectFilterTypes),
    REMOVED_FROM_PUBLIC_VIEW: 'removedFromPublicView',
    CATEGORIES: 'categories',
};

exports.projectScopeFilterTypes = [
    'allProjects',
    'myComments',
    'myDepartment',
    'myEvaluations',
    'myProjects',
    'myReviews',
    'following',
];

exports.projectScopeFilterTypesDict = listToDict(exports.projectScopeFilterTypes);

exports.projectSortFieldTypes = [
    'contactFirstName',
    'created_at',
    'departmentName',
    'procurementFirstName',
    'proposalDeadline',
    'releaseProjectDate',
    'title',
];

exports.projectSortFieldTypesDict = listToDict(exports.projectSortFieldTypes);

// list of valid sort fields and default sort directions
exports.projectSortFieldAndDirectionMap = {
    contactFirstName: 'ASC',
    created_at: 'DESC',
    departmentName: 'ASC',
    procurementFirstName: 'ASC',
    proposalDeadline: 'DESC',
    releaseProjectDate: 'DESC',
    title: 'ASC',
};

exports.projectSortDirectionTypes = new Set(['asc', 'ASC', 'desc', 'DESC']);

exports.projectStatusFilterTypes = [
    'all',
    'active',
    'finalDocument',
    'pendingAward',
    'cleanupDocuments',
    'cleanupIntakes',
];

exports.projectStatusFilterTypesDict = listToDict(exports.projectStatusFilterTypes);

// default is index 0 - don't change that
// NOTE: the order here matters!
exports.projectStatuses = [
    'requestDraft',
    'requestReview',
    'draft',
    'review',
    'final', // Used in place of 'open' for governments without sourcing module
    'postPending',
    'open',
    'auctionPending',
    'reverseAuction',
    'pending',
    'evaluation',
    'awardPending',
    'closed',
];

exports.projectStatusesDict = listToDict(exports.projectStatuses);

exports.projectStatusesOrderMap = buildCustomSortOrderMap(exports.projectStatuses);

exports.intakeProjectStatuses = [
    exports.projectStatusesDict.REQUEST_DRAFT,
    exports.projectStatusesDict.REQUEST_REVIEW,
];

exports.builderProjectStatuses = [
    exports.projectStatusesDict.DRAFT,
    exports.projectStatusesDict.REVIEW,
    exports.projectStatusesDict.FINAL,
];

exports.sourcingProjectStatuses = [
    exports.projectStatusesDict.POST_PENDING,
    exports.projectStatusesDict.OPEN,
    exports.projectStatusesDict.AUCTION_PENDING,
    exports.projectStatusesDict.REVERSE_AUCTION,
    exports.projectStatusesDict.PENDING,
];

exports.evaluationProjectStatuses = [
    exports.projectStatusesDict.EVALUATION,
    exports.projectStatusesDict.AWARD_PENDING,
];

exports.comingSoonStatuses = [
    exports.projectStatusesDict.DRAFT,
    exports.projectStatusesDict.REVIEW,
    exports.projectStatusesDict.FINAL,
    exports.projectStatusesDict.POST_PENDING,
];

exports.projectCalendarSyncStatuses = [
    exports.projectStatusesDict.FINAL,
    exports.projectStatusesDict.POST_PENDING,
    exports.projectStatusesDict.OPEN,
    exports.projectStatusesDict.AUCTION_PENDING,
    exports.projectStatusesDict.REVERSE_AUCTION,
    exports.projectStatusesDict.PENDING,
    exports.projectStatusesDict.EVALUATION,
    exports.projectStatusesDict.AWARD_PENDING,
];

exports.projectTypes = ['addendum', 'contract', 'other', 'purchase'];

exports.projectTypesDict = listToDict(exports.projectTypes);

exports.projectTypesPaths = ['projects', 'contracts'];

exports.projectTypesPathsDict = listToDict(exports.projectTypesPaths);

exports.projectPermissions = ['creator', 'edit', 'view'];

exports.projectPermissionsDict = listToDict(exports.projectPermissions);

exports.getBuilderDisplayName = (project) => {
    let name = 'Project';
    if (project && project.isIntake) {
        name = 'Project Request';
    } else if (
        project &&
        project.type === exports.projectTypesDict.CONTRACT &&
        project.isDocBuilder
    ) {
        name = 'Contract Package';
    } else if (project && project.type === exports.projectTypesDict.CONTRACT) {
        name = 'Contract Document';
    } else if (project && project.isDocBuilder) {
        name = 'Document';
    }
    return name;
};

exports.projectVendorSubscriptionTypes = ['prime', 'sub', 'planRoom'];

exports.projectVendorSubscriptionTypesDict = listToDict(exports.projectVendorSubscriptionTypes);

exports.BID_UNSEALED_AT = 'bidUnsealedAt';

exports.projectAuctionMaxFractionDigits = [0, 2, 3, 4];

exports.projectAuctionMaxFractionDigitsCurrencyMap = {
    0: 1,
    2: 0.01,
    3: 0.001,
    4: 0.0001,
};

exports.compilerSectionTypes = listToDict([
    'attachments',
    'attachments_table_of_contents',
    'project_document',
    'signatures',
]);

exports.projectDeliveryMethods = listToDict(['email', 'url', 'save']);

let bucket = `${process.env.NODE_ENV}-government-project`;

if (__PROD__) {
    bucket = 'government-project';
}

exports.bucket = bucket;
